import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { map } from 'rxjs/operators';
import { Client, ClientSummary } from './client';
import { FinancialData } from './financial/financial-data';
import { Observable } from 'rxjs';

@Injectable({providedIn:'root'})
export class ClientsRepository extends RepositoryImpl<ClientSummary, Client> {
  constructor(api: ApiService) {
    super(api.root.path('clients'), ClientSummary, Client);
  }

  recalculateLimit(client: Client): Observable<FinancialData> {
    return this.api
      .path(client.id)
      .path('usedCreditLimit')
      .getJson<any[]>()
      .pipe(map(r => FinancialData.fromJson(r)));
  }
}
